import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';

import TeamView from "./pages/Team/View";

function App() {
  return (
    <Routes>
      <Route
          path="/"
          element={<Navigate replace to="/team" />}
        />
      <Route path="team">
        <Route index element={<TeamView />} />
      </Route>
    </Routes>
  );
}

export default App;
