import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import {
  DataGridPro,
  GridToolbar,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import Chip from "@mui/material/Chip";
import moment from "moment";
import "moment/locale/th";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const validation = Yup.object().shape({
  teamId: Yup.string().required("กรุณาระบุ"),
});

const mask = (cc, num = 5, mask = "*") =>
  `${cc}`.slice(num).padStart(`${cc}`.length, mask);

const MaskComponent = (props) => {
  const [isHide, setIsHide] = useState(true);
  return (
    <>
      <div onClick={() => setIsHide(!isHide)}>
        {isHide ? mask(props.text) : props.text}
      </div>
    </>
  );
};

const localeText = {
  // Root
  noRowsLabel: "ไม่มีข้อมูล",
  noResultsOverlayLabel: "ไม่พบข้อมูล",
  errorOverlayDefaultLabel: "เกิดข้อผิดพลาด.",

  // Density selector toolbar button text
  toolbarDensity: "การแสดงผล",
  toolbarDensityLabel: "การแสดงผล",
  toolbarDensityCompact: "เล็ก",
  toolbarDensityStandard: "กลาง",
  toolbarDensityComfortable: "ใหญ่",

  // Columns selector toolbar button text
  toolbarColumns: "แสดงข้อมูล",
  toolbarColumnsLabel: "เลือก columns",

  // Filters toolbar button text
  toolbarFilters: "กรองข้อมูล",
  toolbarFiltersLabel: "แสดงตัวกรอง",
  toolbarFiltersTooltipHide: "ซ่อนตัวกรอง",
  toolbarFiltersTooltipShow: "แสดงตัวกรอง",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} ตัวกรอง` : `${count} ตัวกรอง`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "ค้นหา...",
  toolbarQuickFilterLabel: "ค้นหา",
  toolbarQuickFilterDeleteIconLabel: "ล้างค่า",

  // Export selector toolbar button text
  toolbarExport: "นำออก",
  toolbarExportLabel: "นำออก",
  toolbarExportCSV: "ดาวน์โหลด CSV",
  toolbarExportPrint: "พิมพ์",
  toolbarExportExcel: "ดาวน์โหลด Excel",

  // Column menu text
  columnMenuLabel: "เมนู",
  columnMenuShowColumns: "แสดง columns",
  columnMenuManageColumns: "จัดการ columns",
  columnMenuFilter: "ตัวกรอง",
  columnMenuHideColumn: "ซ่อน column",
  columnMenuUnsort: "ไม่จัดเรียง",
  columnMenuSortAsc: "เรียงจาก น้อยไปมาก",
  columnMenuSortDesc: "เรียงจาก มากไปน้อย",

  // Column pinning text
  pinToLeft: "ปักไว้ด้านซ้าย",
  pinToRight: "ปักไว้ด้านขวา",
  unpin: "ไม่ปัก",
};

const dataGridStyle = {
  "& .MuiDataGrid-toolbarContainer": {
    paddingBottom: "4px",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#8fcbb1",
  },
  "& .MuiDataGrid-columnSeparator": {
    color: "#8fcbb1",
  },
  "& .toolbar-button": {
    color: "#2e7d32",
    padding: "5px 10px",
  },
  "& .toolbar-quickfilter": {
    border: "1px solid #eee",
    borderRadius: "4px",
  },
  "& .toolbar-quickfilter .MuiSvgIcon-root": {
    padding: "5px 5px 0px 10px",
    border: "0 !important",
  },
  "& input": {
    marginTop: "1px",
    padding: "5px 0px 0px 0",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "0px !important",
  },
  "& .MuiInput-underline::after": {
    border: "0 !important",
  },
};

const View = () => {
  moment().locale("th");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    // defaultValues: schema,
    resolver: yupResolver(validation),
  });
  const [formData, setFormData] = useState("");
  const [fetchData, setFetchData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getTeamData = async (teamId) => {
    try {
      setIsLoading(true);
      setError("");
      setFetchData("");
      const result = await axios.get(
        `https://asia-east2-minenoti-app.cloudfunctions.net/report/team?teamId=${teamId}`
      );
      if (result.data.status === "success") {
        result.data.result.used_email_noti_qty =
          result.data.result.used_email_noti_qty || 0;
        result.data.result.used_line_noti_qty =
          result.data.result.used_line_noti_qty || 0;
        result.data.result.used_sms_noti_qty =
          result.data.result.used_sms_noti_qty || 0;
        result.data.result.quota_email_qty =
          result.data.result.quota_email_qty ||
          result.data.result.quota_email_noti_qty ||
          0;
        result.data.result.quota_line_qty =
          result.data.result.quota_line_noti_qty || 0;
        result.data.result.quota_sms_qty =
          result.data.result.quota_sms_qty ||
          result.data.result.quota_sms_noti_qty ||
          0;
        setFetchData(result.data.result);
      } else {
        setFetchData("");
        setError("ไม่พบข้อมูล");
      }
      reset({
        teamId: "",
      });
      setIsLoading(false);
    } catch (error) {
      setError("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      console.log(error);
      if (error.response.data) setError(error.response.data.message);
      setIsLoading(false);
    }
  };

  const columnsSubscriptions = [
    {
      field: "ID",
      headerName: "เลขที่คำสั่งซื้อ",
      width: 200,
      valueGetter: (params) => {
        return params?.row.id;
      },
    },
    {
      field: "Name",
      headerName: "แพคเกจ",
      width: 200,
      valueGetter: (params) => {
        return `${params?.row.name} ${params?.row.description}`;
      },
      renderCell: (params) => {
        return (
          <div>
            <div>{params?.row.name}</div>
            <div>{params?.row.description}</div>
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "สถานะ",
      width: 100,
      renderCell: (params) => {
        if (params?.row.status === "active")
          return <Chip label={"Active"} color="success" size="small" />;
        else if (params?.row.status === "inactive")
          return <Chip label={"Inactive"} color="error" size="small" />;
        return <Chip label={params?.row.status} size="small" />;
      },
    },
    {
      field: "Service",
      headerName: "ช่องทาง",
      width: 120,
      valueGetter: (params) => {
        return params?.row.service;
      },
    },
    {
      field: "Quota",
      headerName: "โควต้า",
      width: 100,
      valueGetter: (params) => {
        return params?.row.quota.toLocaleString();
      },
    },
    {
      field: "start_dt",
      headerName: "วันที่เริ่ม",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              <div>{moment(params?.value * 1000).format("ll")}</div>
              <div>{moment(params?.value * 1000).fromNow()}</div>
            </div>
          </>
        );
      },
      valueGetter: (params) => params?.value._seconds,
    },
    {
      field: "end_dt",
      headerName: "วันที่สิ้นสุด",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              <div>{moment(params?.value * 1000).format("ll")}</div>
              <div>{moment(params?.value * 1000).fromNow()}</div>
            </div>
          </>
        );
      },
      valueGetter: (params) => params?.value._seconds,
    },
    {
      field: "created_dt",
      headerName: "วันที่ซื้อ",
      width: 180,
      renderCell: (params) => {
        // return moment(params?.value*1000).format("DD/MM/YYYY hh:mm")
        const a = moment();
        const b = moment(params?.value * 1000);
        const diff = a.diff(b, "days");
        if (diff <= 0) {
          return (
            <>
              <div>{moment(params?.value * 1000).fromNow()}</div>
            </>
          );
        }
        return (
          <>
            <div>{moment(params?.value * 1000).format("lll")}</div>
          </>
        );
      },
      valueGetter: (params) => params?.value._seconds,
    },
  ];

  const columnsTransactions = [
    {
      field: "created_dt",
      headerName: "วันที่",
      width: 180,
      renderCell: (params) => {
        // return moment(params?.value*1000).format("DD/MM/YYYY hh:mm")
        const a = moment();
        const b = moment(params?.value * 1000);
        const diff = a.diff(b, "days");
        if (diff <= 0) {
          return (
            <>
              <div>{moment(params?.value * 1000).fromNow()}</div>
            </>
          );
        }
        return (
          <>
            <div>{moment(params?.value * 1000).format("lll")}</div>
          </>
        );
      },
      valueGetter: (params) => params?.value._seconds,
    },
    {
      field: "ID",
      headerName: "เลขที่รายการ",
      width: 200,
      valueGetter: (params) => {
        return params?.row.id;
      },
    },
    {
      field: "Status",
      headerName: "สถานะ",
      width: 100,
      valueGetter: (params) => {
        if (params?.row.response) {
          if (
            params.row.response.status === 200 ||
            params.row.response.status === "success" ||
            params.row.response.system_code === "200"
          )
            return "Success";
          else if (
            params.row.response.status === 400 ||
            params.row.response.status === "error" ||
            params.row.response.system_code !== "200"
          )
            return "Error";
          return params.row.response.status;
        }
        return "-";
      },
      renderCell: (params) => {
        if (params?.value === "Success")
          return <Chip label={"Success"} color="success" size="small" />;
        else if (params?.value === "Error")
          return <Chip label={"Error"} color="error" size="small" />;
        return <Chip label={params?.value} size="small" />;
      },
    },
    {
      field: "Service",
      headerName: "ช่องทาง",
      width: 120,
      valueGetter: (params) => {
        return params?.row.service;
      },
    },
    // { field: 'Sender', headerName: 'ผู้ส่ง', width: 120,  valueGetter: (params) => {return params?.row.request.sender}},
    // { field: 'To', headerName: 'ผู้รับ', width: 200,  valueGetter: (params) => {return mask(params?.row.request.to)}},
    {
      field: "To",
      headerName: "ผู้รับ",
      width: 200,
      valueGetter: (params) => {
        return params?.row.request.to;
      },
      renderCell: (params) => {
        return <MaskComponent text={params?.value} />;
      },
    },
    {
      field: "Message",
      headerName: "ข้อความ",
      width: 300,
      valueGetter: (params) => {
        const message = params?.row.request.message;
        if (typeof message === "object" && message !== null)
          return JSON.stringify(message);
        return message;
      },
    },
    {
      field: "Charged Qty",
      headerName: "จำนวนที่ใช้งาน",
      width: 120,
      valueGetter: (params) => {
        return params?.row.charged_qty;
      },
    },
    // { field: 'Code', headerName: 'Code', width: 200,  valueGetter: (params) => {return params?.row.response.system_code}},
    {
      field: "System Message",
      headerName: "ข้อความจากระบบ",
      width: 400,
      valueGetter: (params) => {
        const system_message = params?.row.response.system_message;
        if (typeof system_message === "object" && system_message !== null)
          return JSON.stringify(system_message);
        return system_message;
      },
    },
  ];

  const [sortModelSubscriptions, setSortModelSubscriptions] = useState([]);

  const [sortModelTransactions, setSortModelTransactions] = useState([
    {
      field: "created_dt",
      sort: "desc",
    },
  ]);

  const csvOptions = {
    utf8WithBom: true,
  };

  function QuickSearchToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          m: 1,
        }}
      >
        <GridToolbarQuickFilter
          className={"toolbar-quickfilter"}
          debounceMs={200}
          quickFilterParser={(searchInput) =>
            searchInput.split(",").map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) =>
            quickFilterValues.join(", ")
          }
        />
        <GridToolbarFilterButton
          className={"toolbar-button"}
          sx={{
            ml: 2,
          }}
        />
        <GridToolbarExport
          className={"toolbar-button"}
          csvOptions={csvOptions}
        />
        <GridToolbarDensitySelector className={"toolbar-button"} />
        <GridToolbarColumnsButton className={"toolbar-button"} />
      </GridToolbarContainer>
    );
  }

  moment.locale("th");

  function CircularProgressWithLabel(props) {
    let color = "#eee";
    if (Math.round(props.value) > 90) color = "#2e7d32";
    else if (Math.round(props.value) > 75) color = "#8bc34a";
    else if (Math.round(props.value) > 60) color = "#ffea00";
    else if (Math.round(props.value) > 40) color = "##ff9100";
    else if (Math.round(props.value) > 20) color = "#b2102f";
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} sx={{ color }} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={{ marginBottom: 5 }}>MineNoti</h1>
              <div>บริการแจ้งเตือนผ่าน Line, Email, SMS</div>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit((data) => getTeamData(data.teamId))}>
                <Controller
                  name="teamId"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ minWidth: "250px" }}
                      id="outlined-error-helper-text"
                      label="หมายเลขผู้ใช้งาน"
                      variant="outlined"
                      {...field}
                      error={errors.teamId}
                      size="small"
                      helperText={errors.teamId && errors.teamId.message}
                    />
                  )}
                />

                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  style={{
                    marginLeft: 10,
                    backgroundColor: "rgb(46, 125, 50)",
                  }}
                >
                  ค้นหา
                </Button>
                <div
                  style={{ marginTop: 10, color: "red", fontSize: "0.9rem" }}
                >
                  {error === "team not found" ? "ไม่พบข้อมูล" : error}
                </div>
              </form>
            </Grid>
            <Grid item xs={12}>
              {fetchData && (
                <>
                  <div>
                    <h2 style={{ marginBottom: 5 }}>
                      <span style={{ marginRight: 10 }}>{fetchData.name}</span>
                      {fetchData.status === "active" && (
                        <Chip label={"Active"} color="success" size="small" />
                      )}
                      {fetchData.status !== "active" && (
                        <Chip label={"Inactive"} color="error" size="small" />
                      )}
                    </h2>
                    <div>รายละเอียด: {fetchData.description}</div>
                    <div>หมายเลขผู้ใช้งาน: {fetchData.id}</div>

                    {fetchData && (
                      <>
                        <TableContainer
                          component={Paper}
                          sx={{ maxWidth: 550, marginTop: 2 }}
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow
                                sx={{
                                  backgroundColor: "#8fcbb1",
                                }}
                              >
                                <TableCell>ช่องทาง</TableCell>
                                <TableCell align="right">ใช้ไปแล้ว</TableCell>
                                <TableCell align="right">โควต้า</TableCell>
                                <TableCell align="right">คงเหลือ</TableCell>
                                <TableCell align="center">% คงเหลือ</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Email
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.used_email_noti_qty.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.quota_email_qty.toLocaleString()}{" "}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.quota_email_qty -
                                    fetchData.used_email_noti_qty
                                  ).toLocaleString()}
                                </TableCell>
                                {/* <TableCell align="right"><Chip label={`${((fetchData.quota_email_qty-fetchData.used_email_noti_qty)/fetchData.quota_email_qty * 100).toFixed(0)}%`} variant="outlined" /></TableCell> */}
                                <TableCell align="center">
                                  <CircularProgressWithLabel
                                    value={Math.floor(
                                      ((fetchData.quota_email_qty -
                                        fetchData.used_email_noti_qty) /
                                        fetchData.quota_email_qty) *
                                        100
                                    ).toFixed(0)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Line
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.used_line_noti_qty.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.quota_line_qty.toLocaleString()}{" "}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.quota_line_qty -
                                    fetchData.used_line_noti_qty
                                  ).toLocaleString()}
                                </TableCell>
                                {/* <TableCell align="right"><Chip label={`${((fetchData.quota_line_qty - fetchData.used_line_qty)/fetchData.quota_line_qty * 100).toFixed(0)}%`} variant="outlined" /></TableCell> */}
                                <TableCell align="center">
                                  <CircularProgressWithLabel
                                    value={Math.floor(
                                      ((fetchData.quota_line_qty -
                                        fetchData.used_line_noti_qty) /
                                        fetchData.quota_line_qty) *
                                        100
                                    ).toFixed(0)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  SMS
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.used_sms_noti_qty.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {fetchData.quota_sms_qty.toLocaleString()}{" "}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.quota_sms_qty -
                                    fetchData.used_sms_noti_qty
                                  ).toLocaleString()}
                                </TableCell>
                                {/* <TableCell align="right"><Chip label={`${((fetchData.quota_sms_qty-fetchData.used_sms_qty)/fetchData.quota_sms_qty * 100).toFixed(0)}%`} variant="outlined" /></TableCell> */}
                                <TableCell align="center">
                                  <CircularProgressWithLabel
                                    value={Math.floor(
                                      ((fetchData.quota_sms_qty -
                                        fetchData.used_sms_noti_qty) /
                                        fetchData.quota_sms_qty) *
                                        100
                                    ).toFixed(0)}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  รวม
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.used_email_noti_qty +
                                    fetchData.used_line_noti_qty +
                                    fetchData.used_sms_noti_qty
                                  ).toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.quota_email_qty +
                                    fetchData.quota_line_qty +
                                    fetchData.quota_sms_qty
                                  ).toLocaleString()}{" "}
                                </TableCell>
                                <TableCell align="right">
                                  {(
                                    fetchData.quota_email_qty +
                                    fetchData.quota_line_qty +
                                    fetchData.quota_sms_qty -
                                    (fetchData.used_email_noti_qty +
                                      fetchData.used_line_noti_qty +
                                      fetchData.used_sms_noti_qty)
                                  ).toLocaleString()}
                                </TableCell>
                                {/* <TableCell align="right"><Chip label={`${((fetchData.quota_total_qty-fetchData.used_total_qty)/fetchData.quota_total_qty * 100).toFixed(0)}%`} variant="outlined" /></TableCell> */}
                                <TableCell align="center">
                                  <CircularProgressWithLabel
                                    value={(
                                      ((fetchData.quota_email_qty +
                                        fetchData.quota_line_qty +
                                        fetchData.quota_sms_qty -
                                        (fetchData.used_email_noti_qty +
                                          fetchData.used_line_noti_qty +
                                          fetchData.used_sms_noti_qty)) /
                                        (fetchData.quota_email_qty +
                                          fetchData.quota_line_qty +
                                          fetchData.quota_sms_qty)) *
                                      100
                                    ).toFixed(0)}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </div>

                  <div style={{ display: "flex", height: "400px" }}>
                    <div style={{ flexGrow: 1 }}>
                      <h2>คำสั่งซื้อ</h2>
                      <DataGridPro
                        rows={fetchData.subscriptions || []}
                        columns={columnsSubscriptions}
                        sortModel={sortModelSubscriptions}
                        onSortModelChange={(model) =>
                          setSortModelSubscriptions(model)
                        }
                        components={{ Toolbar: QuickSearchToolbar }}
                        pagination
                        sx={dataGridStyle}
                        localeText
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      height: "750px",
                      marginTop: "70px",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <h2>รายการ</h2>
                      <DataGridPro
                        rows={fetchData.transactions || []}
                        columns={columnsTransactions}
                        sortModel={sortModelTransactions}
                        onSortModelChange={(model) =>
                          setSortModelTransactions(model)
                        }
                        components={{ Toolbar: QuickSearchToolbar }}
                        pagination
                        sx={dataGridStyle}
                        localeText
                      />
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default View;
